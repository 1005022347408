/**
 * A helper function to calculate the default weight for a selection
 * score based on the number of enabled score fields.
 *
 * This function returns an truncated, un-rounded weight of at most three
 * decimal places, e.g. 0.25, 0.5, 0.333, etc.
 *
 * @param {number} count
 * @returns {number} the truncated default weight
 */
export default function defaultWeight(count) {
  return Math.floor((1 / count) * 1000) / 1000;
}
