import { Controller } from "@hotwired/stimulus";
import Rollbar from "rollbar";

import csrfToken from "../lib/csrf_token";
import { checkStatus } from "../lib/http-utils";

export default class extends Controller {
  static targets = ["errorMessage", "switch"];
  static values = { url: String };

  toggle() {
    this.clearErrorState();
    this.setSendingState(true);

    const { name, value } = this.switchTarget;

    const formData = new FormData();
    formData.append(name, this.switchState ? value : 0);

    this.submit(formData);
  }

  async submit(settings) {
    try {
      const response = await fetch(this.urlValue, {
        body: settings,
        credentials: "same-origin",
        headers: {
          "X-CSRF-Token": csrfToken(),
        },
        method: "PATCH",
      });
      await checkStatus(response);
    } catch (error) {
      this.setErrorState(error);
      this.resetSwitch();
    } finally {
      this.clearSendingState(false);
    }
  }

  addClass(htmlClass) {
    this.element.classList.add(htmlClass);
  }

  clearErrorState() {
    this.removeClass(this.errorHtmlClass);
  }

  clearSendingState() {
    this.removeClass(this.sendingHtmlClass);
  }

  removeClass(htmlClass) {
    this.element.classList.remove(htmlClass);
  }

  resetSwitch() {
    this.switchTarget.checked = !this.switchState;
  }

  setErrorState(errorMessage) {
    if (typeof Rollbar !== "undefined") {
      Rollbar.error(
        `Request to ${this.urlValue} failed. Error: ${errorMessage}`
      );
    }

    this.addClass(this.errorHtmlClass);
  }

  setSendingState() {
    this.addClass(this.sendingHtmlClass);
  }

  get errorHtmlClass() {
    return `${this.identifier}--error`;
  }

  get sendingHtmlClass() {
    return `${this.identifier}--sending`;
  }

  get switchState() {
    return this.switchTarget.checked;
  }
}
