import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["selectedItem"];
  static targets = ["actions", "item", "selectionCount", "viewOptions"];

  connect() {
    this._selectedItems.forEach((item) => item.setSelectionIndicator(true));
    this._toggleHeader();

    this.element.addEventListener(
      "input",
      this._handleSelectionTargetChanged.bind(this)
    );
  }

  selectAll(event) {
    event.preventDefault();

    this._items.forEach((item) => item.select(true));

    this._updateSelectionCount();

    this._dispatchSelectionChanged();
  }

  selectByFilter(event) {
    event.preventDefault();

    const selectedFilter = event.currentTarget.dataset.selectListFilter;

    // iterate over everything to properly select and de-select
    this._items.forEach((item) => item.selectIf(selectedFilter));

    this._updateSelectionCount();

    this._dispatchSelectionChanged();
  }

  unselectAll(event) {
    event.preventDefault();

    this._items.forEach((item) => item.select(false));

    this._updateSelectionCount();

    this._dispatchSelectionChanged();
  }

  get _anythingSelected() {
    return this._selectedItems.length > 0;
  }

  get _items() {
    return this.itemTargets.map(
      (item) => new Item(item, this.selectedItemClass)
    );
  }

  get _selectedItems() {
    return this._items.filter((item) => item.isSelected());
  }

  _dispatchSelectionChanged() {
    const selectedValues = this._selectedItems.map((item) => item.value);
    const selectionCount = this._selectedItems.length;
    this._toggleHeader();

    this.dispatch("selectionChanged", {
      detail: { selectedValues, selectionCount },
    });
  }

  _handleSelectionTargetChanged(event) {
    const { target } = event;
    const changedItem = this._items.find((item) =>
      item.hasSelectionTarget(target)
    );
    changedItem?.syncSelectionIndicator();

    this._updateSelectionCount();

    this._dispatchSelectionChanged();
  }

  _toggleActions() {
    if (this.hasActionsTarget) {
      const { hideClass, showClass } = this.actionsTarget.dataset;

      if (this._anythingSelected) {
        this.actionsTarget.classList.add(showClass);
        this.actionsTarget.classList.remove(hideClass);
      } else {
        this.actionsTarget.classList.add(hideClass);
        this.actionsTarget.classList.remove(showClass);
      }
    }
  }

  _toggleViewOptions() {
    if (this.hasViewOptionsTarget) {
      const { hideClass, showClass } = this.viewOptionsTarget.dataset;

      if (this._anythingSelected) {
        this.viewOptionsTarget.classList.add(hideClass);
        this.viewOptionsTarget.classList.remove(showClass);
      } else {
        this.viewOptionsTarget.classList.add(showClass);
        this.viewOptionsTarget.classList.remove(hideClass);
      }
    }
  }

  _toggleHeader() {
    this._toggleViewOptions();
    this._toggleActions();
  }

  _updateSelectionCount() {
    if (this.hasSelectionCountTarget) {
      const selectedItemCount = this._selectedItems.length;

      this.selectionCountTarget.querySelector(".count").innerText =
        selectedItemCount;

      if (selectedItemCount === 0) {
        this.selectionCountTarget.classList.add("d-none");
      } else {
        this.selectionCountTarget.classList.remove("d-none");
      }
    }
  }
}

class Item {
  constructor(element, selectedItemClass) {
    this.element = element;
    this.selectedItemClass = selectedItemClass;
  }

  hasSelectionTarget(element) {
    return this.selectionTarget === element;
  }

  isSelected() {
    return this.selectionTarget.checked;
  }

  select(checked) {
    this.selectionTarget.checked = checked;
    this.setSelectionIndicator(checked);
  }

  selectIf(filter) {
    this.select(this.supportsFilter(filter));
  }

  setSelectionIndicator(selected) {
    if (selected) {
      this.element.classList.add(this.selectedItemClass);
    } else {
      this.element.classList.remove(this.selectedItemClass);
    }
  }

  supportsFilter(filter) {
    return this.supportedFilters.includes(filter);
  }

  syncSelectionIndicator() {
    this.setSelectionIndicator(this.isSelected());
  }

  get selectionTarget() {
    return this.element.querySelector(".select-item-selection-target");
  }

  get supportedFilters() {
    return (this.element.dataset.selectListItemFilterValues || "")
      .split(" ")
      .filter((item) => item.length > 0);
  }

  get value() {
    return this.element.dataset.selectListItemValue;
  }
}
