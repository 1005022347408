import { Controller } from "@hotwired/stimulus";

/**
 * Sets a default value on a form field, based on the selected value of another
 *
 * NOTE: This is just a basic implementation, designed to set text fields from a
 *       selected <option>, but should be modified to support additional
 *       selection field types, and additional target field types.
 */
export default class extends Controller {
  selectionChanged(event) {
    const selectField = event.target;
    const selectedOption = selectField.options[selectField.selectedIndex];
    const defaultValuesString = selectedOption.dataset.defaultValues;
    const defaultValues = JSON.parse(defaultValuesString);

    for (let fieldName in defaultValues) {
      if (Object.prototype.hasOwnProperty.call(defaultValues, fieldName)) {
        const targetField = document.querySelector(`[name="${fieldName}"]`);

        if (targetField) {
          targetField.value = defaultValues[fieldName];
        }
      }
    }
  }
}
