import { Controller } from "@hotwired/stimulus";

const HIGHLIGHT_CLASS = "table-primary";
const ROW_SELECTOR = ".item-row";

export default class extends Controller {
  static targets = ["formComponent", "toField"];

  connect() {
    this.checkedCheckboxes.map((cb) =>
      this.highlightRow(cb.closest(ROW_SELECTOR))
    );
  }

  anySelected() {
    return this.checkboxes.some((element) => element.checked);
  }

  handleAllSelected(event) {
    const allSelected = event.target.checked;

    // Don't deselect everything when unchecked, this allows users to
    // selected all and then deselect a few.
    if (allSelected) {
      this.checkboxes.forEach((element) => (element.checked = true));
      this.allRows.forEach((row) => this.highlightRow(row));
      this.updateComponents(true);
    }
  }

  highlightRow(row) {
    row.classList.add(HIGHLIGHT_CLASS);
  }

  handleItemSelected(event) {
    const checkbox = event.target;
    const row = checkbox.closest(ROW_SELECTOR);

    if (checkbox.checked) {
      this.highlightRow(row);
    } else {
      this.unhighlightRow(row);
    }

    this.updateComponents(this.anySelected());
  }

  unhighlightRow(row) {
    row.classList.remove(HIGHLIGHT_CLASS);
  }

  updateComponents(enabled) {
    this.formComponentTargets.forEach(
      (component) => (component.disabled = !enabled)
    );
  }

  get allRows() {
    return this.checkboxes.map((cb) => cb.closest(ROW_SELECTOR));
  }

  get checkboxes() {
    return new Array(...this.element.querySelectorAll(".panelist-checkbox"));
  }

  get checkedCheckboxes() {
    return new Array(
      ...this.element.querySelectorAll(".panelist-checkbox:checked")
    );
  }
}
