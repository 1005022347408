import { Controller } from "@hotwired/stimulus";
import csrfToken from "../lib/csrf_token";

export default class extends Controller {
  static classes = ["selected"];
  static targets = ["downVoteButton", "messageContainer", "upVoteButton"];
  static values = {
    downVoteUrl: String,
    upVoteUrl: String,
  };

  downvote(event) {
    event.preventDefault();

    this._sendVote(
      this.downVoteUrlValue,
      this.downVoteButtonTarget,
      this.upVoteButtonTarget
    );
  }

  upvote(event) {
    event.preventDefault();

    this._sendVote(
      this.upVoteUrlValue,
      this.upVoteButtonTarget,
      this.hasDownVoteButtonTarget ? this.downVoteButtonTarget : undefined
    );
  }

  _clearErrorState() {
    this.messageContainerTarget.innerText = "";
    this.messageContainerTarget.classList.add("d-none");
  }

  async _sendVote(url, selectedButton, notSelectedButton) {
    const settings = {
      credentials: "same-origin",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken(),
      },
    };
    this._clearErrorState();
    try {
      const response = await fetch(url, settings);
      const voteStats = await response.json();
      // Toggle to handle undo
      selectedButton.classList.toggle(this.selectedClass);

      // Sycn titles with class
      if (selectedButton.classList.contains("vote-button--voted")) {
        selectedButton.setAttribute("title", "Click to undo");
      } else {
        selectedButton.setAttribute("title", "Click to vote");
      }

      // for up-vote only cases
      if (notSelectedButton) {
        notSelectedButton.classList.remove(this.selectedClass);

        // Sycn titles with class
        if (notSelectedButton.classList.contains("vote-button--voted")) {
          notSelectedButton.setAttribute("title", "Click to undo");
        } else {
          notSelectedButton.setAttribute("title", "Click to vote");
        }
      }

      this.dispatch("cast", { detail: voteStats });
    } catch (e) {
      Rollbar.error(e);
      this._setErrorState(
        "Oops! An error occurred recording your vote. Please refresh the page and try again."
      );
    }
  }

  _setErrorState(message) {
    this.messageContainerTarget.innerText = message;
    this.messageContainerTarget.classList.remove("d-none");
  }
}
