import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // NOTE: Prefer more generic targets and actions over specific ones,
  //       rather then referencing a specific field.
  //       e.g. scipiOnlyField is preferred.
  static targets = [
    "allowGuestParticipation",
    "allowGuestParticipationHint",
    "applicationField",
    "commentsCloseDate",
    "commentsCloseDateHint",
    "contractTemplateLabel",
    "contractTemplateField",
    "defaultPayRate",
    "resultsPublic",
    "resultsPublicHint",
    "scipollAlert",
    "scipiOnlyField",
    "scipiOnlyHint",
    "scipiOnlyLabel",
    "selectionClosesOn",
    "selectionClosesOnHint",
    "selectionClosesOnLabel",
    "paymentText",
  ];

  brandingChange(event) {
    // Add other brandings as needed
    const { isScipi, isScipoll } = event.params;

    this.scipiOnlyFieldTargets.forEach((field) => {
      field.toggleAttribute("disabled", !isScipi);
    });
    this.scipiOnlyHintTargets.forEach((hint) => {
      if (isScipi) {
        hint.classList.remove("text-muted");
      } else {
        hint.classList.add("text-muted");
      }
    });
    this.scipiOnlyLabelTargets.forEach((label) => {
      if (isScipi) {
        label.classList.remove("text-muted");
      } else {
        label.classList.add("text-muted");
      }
    });

    if (this.hasScipollAlertTarget) {
      this.scipollAlertTargets.forEach((scipollAlert) => {
        if (isScipoll) {
          scipollAlert.classList.remove("d-none");
        } else {
          scipollAlert.classList.add("d-none");
        }
      });
    }
  }

  changeApplicationState(event) {
    if (!this.hasApplicationFieldTarget) return;

    const requiresApplication = event.target.value === "true";

    this.applicationFieldTargets.forEach(
      (field) => (field.disabled = !requiresApplication)
    );
    this.allowGuestParticipationTarget.disabled = requiresApplication;
    this.allowGuestParticipationHintTarget.classList.toggle("text-muted");
    this.selectionClosesOnTarget.disabled = !requiresApplication;
    this.selectionClosesOnLabelTarget.classList.toggle("text-muted");
    this.selectionClosesOnHintTarget.classList.toggle("text-muted");
  }

  changeCommentsAllowedState(event) {
    const commentsAllowed = event.target.checked;

    this.commentsCloseDateTarget.disabled = !commentsAllowed;
    this.commentsCloseDateHintTarget.classList.toggle("text-muted");
  }

  changeContractRequiredState(event) {
    const contractsRequired = event.target.checked;

    this.contractTemplateFieldTargets.forEach(
      (field) => (field.disabled = !contractsRequired)
    );
    this.contractTemplateLabelTargets.forEach((label) =>
      label.classList.toggle("text-muted")
    );
  }

  changeResultsPublishedState(event) {
    const resultsPublished = event.target.checked;

    this.resultsPublicTarget.disabled = !resultsPublished;
    this.resultsPublicHintTarget.classList.toggle("text-muted");
  }
}
