import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["field", "trigger"];

  toggle(event) {
    event.preventDefault();

    if (this.fieldTarget.type === "password") {
      this.changeField({ type: "text", label: "Hide" });
    } else {
      this.changeField({ type: "password", label: "Show" });
    }
  }

  changeField({ type, label }) {
    this.fieldTarget.type = type;
    this.triggerTarget.innerText = label;
  }
}
