import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dateInput", "publishButton"];

  connect() {
    this.updateButton();
  }

  updateButton() {
    const dateValue = this.dateInputTarget.value;
    const date = dateValue ? new Date(dateValue) : null;
    const now = new Date();

    if (!date) {
      this.publishButtonTarget.value = "Publish Now!";
    } else if (date >= now) {
      this.publishButtonTarget.value = "Schedule Publication for";
    } else {
      this.publishButtonTarget.value = "Publish Now! (backdated on)";
    }
  }
}
