import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["action", "item"];

  connect() {
    this._checkAllActions();
  }

  selectionChanged() {
    this._checkAllActions();
  }

  itemSelected() {
    // Not the most efficient, but likely good enough for most cases
    this._checkAllActions();
  }

  supportedItemsSelected(actionName) {
    if (this.selectedItems.length === 0) return false;

    return this.selectedItems.every((item) => item.supportsAction(actionName));
  }

  get selectedItems() {
    return this.itemTargets
      .filter((item) => item.checked)
      .map((item) => new Item(item));
  }

  _checkAllActions() {
    this.actionTargets.forEach((action) => {
      const actionName = action.dataset.dependentActionsActionName;

      action.disabled = !this.supportedItemsSelected(actionName);
    });
  }
}

class Item {
  constructor(element) {
    this.element = element;
  }

  get supportedActions() {
    return (this.element.dataset.dependentActionsSupportedActions || "")
      .split(" ")
      .filter((action) => action.length > 0);
  }

  supportsAction(action) {
    if (action === "any") return true;

    return this.supportedActions.includes(action);
  }
}
