import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["internalField", "userSelectableField"];

  internalChanged(event) {
    const internal = event.target.checked;

    this.userSelectableFieldTarget.disabled = internal;
  }

  userSelectableChanged(event) {
    const userSelectable = event.target.checked;

    this.internalFieldTarget.disabled = userSelectable;
  }
}
