import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "availableIndicator",
    "existsMessage",
    "progressIndicator",
    "takenIndicator",
  ];

  check(event) {
    this._hide(this.takenIndicatorTarget);
    this._hide(this.availableIndicatorTarget);

    const display_name = event.target.value.trim();
    if (display_name.length === 0) return;

    const url = this.data.get("url");

    this._show(this.progressIndicatorTarget);

    fetch(`${url}?display_name=${display_name}`, { credentials: "same-origin" })
      .then((response) => {
        return response.json();
      })
      .then((payload) => {
        const { exists } = payload;
        if (exists) {
          this._show(this.takenIndicatorTarget);
        } else {
          this._show(this.availableIndicatorTarget);
        }
      })
      .catch((e) => {
        // Exceptions are okay to ignore since uniqueness is checked on the
        // server, this check is purely for convenience.
        // Just log a warning and be done with it!
        Rollbar.warn(`Display name lookup failed: ${e}`);
      })
      .finally(() => {
        this._hide(this.progressIndicatorTarget);
      });
  }

  _hide(target) {
    target.classList.add("d-none");
  }
  _show(target) {
    target.classList.remove("d-none");
  }
}
