import { Controller } from "@hotwired/stimulus";

/**
 * Shows users the status of a recent download.
 *
 * We could download the file via a turbo stream and update the status
 * in a frame, but it's a bit a pain. This works just about as well.
 */
export default class extends Controller {
  static targets = ["success", "before", "downloading"];
  static values = { url: String };

  onClick() {
    this._showDownloadInProgress();
    // 500ms is more then enough time for them to click 'Save' in the save file dialog
    setTimeout(() => this._checkStatus(), 500);
  }

  async _checkStatus() {
    try {
      const response = await fetch(this.urlValue, {
        method: "GET",
      });
      const json = await response.json();
      if (json.downloaded) {
        this._showDownloadSuccess();
      } else {
        // Show nothing if we cannot determine the state
        this._showUndeterminedState();
      }
    } catch {
      // Show nothing if we cannot determine the state
      this._showUndeterminedState();
    }
  }

  _showDownloadInProgress() {
    this.downloadingTarget.classList.remove("d-none");
    this.successTarget.classList.add("d-none");
    this.beforeTarget.classList.add("d-none");
  }

  _showDownloadSuccess() {
    this.successTarget.classList.remove("d-none");
    this.beforeTarget.classList.add("d-none");
    this.downloadingTarget.classList.add("d-none");
  }

  // Show nothing if we cannot determine the state
  _showUndeterminedState() {
    this.successTarget.classList.add("d-none");
    this.beforeTarget.classList.add("d-none");
    this.downloadingTarget.classList.add("d-none");
  }
}
