import { Controller } from "@hotwired/stimulus";
import csrfToken from "../lib/csrf_token";

export default class extends Controller {
  connect() {
    document.addEventListener("change", function (e) {
      if (e.target.matches(".grid-row select")) {
        const gridRow = new WeightedSumGridRow(e.target.closest(".grid-row"));
        gridRow.refreshScore();
      }
    });
  }
}

GridData.prototype.addDataAt = function (rowIndex, colIndex, value) {
  this.gridData[rowIndex][colIndex] = value;
};

GridData.prototype.isComplete = function () {
  return this.gridData.every(function (row) {
    return row.every(function (cell) {
      return cell !== "";
    });
  });
};

function GridAnswerView(gridElement) {
  this.gridElement = gridElement;
}

GridAnswerView.prototype.getData = function () {
  const rowCount = this.gridRowCount();
  const colCount = this.gridColumnCount();

  const gridData = new GridData(rowCount, colCount);

  for (var rowIndex = 0; rowIndex < rowCount; rowIndex++) {
    for (var colIndex = 0; colIndex < colCount; colIndex++) {
      const cellValue = this.cellValueAt(rowIndex, colIndex);
      gridData.addDataAt(rowIndex, colIndex, cellValue);
    }
  }
  return gridData;
};

GridAnswerView.prototype.getIntegerDataAttr = function (el, attrName) {
  return parseInt(el.getAttribute(attrName));
};

GridAnswerView.prototype.gridRowCount = function () {
  return this.getIntegerDataAttr(this.gridElement, "data-row-count");
};

GridAnswerView.prototype.gridColumnCount = function () {
  return this.getIntegerDataAttr(this.gridElement, "data-column-count");
};

GridAnswerView.prototype.cellValueAt = function (rowIndex, colIndex) {
  const cellSelector = `select[data-row-index='${rowIndex}'][data-column-index='${colIndex}']`;
  const cellInput = this.gridElement.querySelector(cellSelector);

  const cellValue = cellInput.value;
  if (isNumeric(cellValue)) {
    // Still a string here, but it's handled on the server
    return cellValue;
  } else {
    return 0;
  }
};

ScoreField.prototype.setScoreNotAvailable = function () {
  this.el.textContent = "N/A";
};

ScoreField.prototype.setScore = function (score) {
  this.el.textContent = score;
};

function WeightedSumGridRow(el) {
  this.el = el;
  this.resultsBox = el.querySelector(".weighted-sum-results");
  this.scoreField = new ScoreField(
    el.querySelector(".weighted-sum-results .form-control.wss-holder")
  );
  const grid = this.el.querySelector("table");
  this.gridView = new GridAnswerView(grid);
  this.errorMessage = this.el.querySelector(
    ".weighted-sum-results .error-message"
  );
}

WeightedSumGridRow.prototype.getQuestionId = function () {
  return this.el.getAttribute("data-question-id");
};

WeightedSumGridRow.prototype.getGridData = function () {
  return this.gridView.getData();
};

WeightedSumGridRow.prototype.showProgressIndicator = function () {
  this.resultsBox.classList.add("working");
};

WeightedSumGridRow.prototype.hideProgressIndicator = function () {
  this.resultsBox.classList.remove("working");
};

WeightedSumGridRow.prototype.clearErrorMessage = function () {
  this.errorMessage.textContent = "";
  this.errorMessage.classList.add("d-none");
};

WeightedSumGridRow.prototype.setErrorMessage = function (message) {
  this.errorMessage.classList.remove("d-none");
  this.errorMessage.textContent = message;
};

WeightedSumGridRow.prototype.successfullyRetrievedScore = function (result) {
  this.hideProgressIndicator();
  this.scoreField.setScore(result.score);
};

WeightedSumGridRow.prototype.errorRetrievingScore = function () {
  this.scoreField.setScoreNotAvailable();
  this.setErrorMessage(
    "An error occurred and we can not show your score. You should still be able to view it from the 'My Submission' page when you are done."
  );
  this.hideProgressIndicator();
};

WeightedSumGridRow.prototype.refreshScore = function () {
  const gridData = this.getGridData();
  const questionId = this.getQuestionId();

  const service = new WeightedSumService(questionId);

  this.clearErrorMessage();

  if (gridData.isComplete()) {
    this.showProgressIndicator();
    service.fetchScore(
      gridData,
      this.successfullyRetrievedScore.bind(this),
      this.errorRetrievingScore.bind(this)
    );
  } else {
    this.scoreField.setScoreNotAvailable();
  }
};

WeightedSumService.prototype.fetchScore = function (
  gridData,
  successCallback,
  errorCallback
) {
  const url = "/questions/" + this.questionId + "/score";

  // This is a bit of a hack, but it's the easiest way to format the payload data how it used to work in jquery
  const formData = new URLSearchParams();
  for (let i = 0; i < gridData.gridData.length; i++) {
    for (let j = 0; j < gridData.gridData[i].length; j++) {
      formData.append(`grid_data[${i}][]`, gridData.gridData[i][j]);
    }
  }

  fetch(url, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "X-CSRF-Token": csrfToken(),
    },
    body: formData,
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then(successCallback)
    .catch(errorCallback);
};

function ScoreField(el) {
  this.el = el;
}

function WeightedSumService(questionId) {
  this.questionId = questionId;
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function GridData(rowCount, colCount) {
  this.gridData = [];
  for (var rowIndex = 0; rowIndex < rowCount; rowIndex++) {
    this.gridData[rowIndex] = [];
    for (var colIndex = 0; colIndex < colCount; colIndex++) {
      this.gridData[rowIndex][colIndex] = "";
    }
  }
}
