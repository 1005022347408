import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox"];
  static values = { maxChoices: Number };
  static classes = ["disabled"];

  connect() {
    this.limitChoices();
  }

  limitChoices() {
    if (!this.maxChoicesValue) return;

    const checkedBoxes = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked
    );

    const isLimitReached = checkedBoxes.length >= this.maxChoicesValue;

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.disabled = isLimitReached && !checkbox.checked;
      this._toggleLabelClass(checkbox, checkbox.disabled);
    });
  }

  // Helper method to toggle the disabled class on labels
  _toggleLabelClass(checkbox, isDisabled) {
    const label = this.element.querySelector(`label[for="${checkbox.id}"]`);

    if (!label) return;

    label.classList.toggle(this.disabledClass, isDisabled);
  }
}
