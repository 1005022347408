import { Controller } from "@hotwired/stimulus";

/**
 * Associates fields with a toggle, where the fields can
 * enabled or disabled, and publishes events on each change.
 * Or updates the toggle when the fields are externally enabled
 * or disabled.
 *
 * TODO: Add the ability to parameterize the behavior,
 *       when needed between read-only and disabled.
 */
export default class extends Controller {
  static targets = ["field", "trigger"];
  static values = { blanks: Array };

  fieldTargetConnected(field) {
    field.addEventListener("blur", () => this.handleValueChange(field));
  }

  handleValueChange(field) {
    const fieldValue = Number(field.value.trim());

    if (this.blanksValue.includes(fieldValue)) {
      this.triggerTarget.checked = false;
      this._toggleFields(false);
    }
  }

  toggle(trigger) {
    const { checked: editable } = trigger;

    this._toggleFields(editable);

    this.dispatch("stateChanged");
  }

  triggerTargetConnected(trigger) {
    trigger.addEventListener("input", () => this.toggle(trigger));
  }

  _toggleFields(editable) {
    this.fieldTargets.forEach((field) => (field.readOnly = !editable));
  }
}
