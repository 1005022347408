import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["expert"];

  async handleExport(event) {
    event.preventDefault();

    const exportPath = event.target.dataset.exportPath;
    const queryString = this.expertIds
      .map((id) => `expert_ids[]=${id}`)
      .join("&");
    window.location.href = `${exportPath}?${queryString}`;
  }

  get expertIds() {
    return this.expertTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.dataset.expertId);
  }
}
