import { Controller } from "@hotwired/stimulus";

import consumer from "../channels/consumer";

// Receives an ActionCable broadcast and redirects the user to the specified
// 'to' URL, if they are on the current 'from' URL.
export default class extends Controller {
  static values = { broadcasting: String };

  connect() {
    consumer.subscriptions.create(
      { channel: "RedirectsChannel", broadcasting: this.broadcastingValue },
      {
        connected() {
          console.log("connected to RedirectsChannel");
        },

        disconnected() {
          console.log("connected to RedirectsChannel");
        },

        received: this._performRedirect.bind(this),
      }
    );
  }

  _performRedirect({ from, to }) {
    if (window.location.href === from) {
      window.location = to;
    }
  }
}
