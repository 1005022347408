import ResultItem from "./ResultItem";

export default class ItemList {
  constructor(element) {
    this.element = element;
    this.resetScrollPosition();
    this.selectionClass = "active";
    this.element.addEventListener(
      "mousedown",
      this.onResultsMouseDown.bind(this)
    );
    this.element.addEventListener("mouseup", this.onResultsMouseUp.bind(this));
    this.element.addEventListener("click", this.onClick.bind(this));
  }

  addEventListener(eventName, handler) {
    this.element.addEventListener(eventName, handler);
  }

  clear() {
    if (!this.element.hidden) {
      this.close();
      this.resetScrollPosition();
      this.element.innerHTML = "";

      this.mouseDown = false;
    }
  }

  close() {
    this.element.hidden = true;
  }

  onClick(event) {
    const { target } = event;
    const item = this.itemFor(target);
    const customEvent = new CustomEvent("autocomplete:itemClicked", {
      detail: { selectedItem: item },
    });
    this.element.dispatchEvent(customEvent);
  }

  onResultsMouseDown() {
    const customEvent = new CustomEvent("autocomplete:selectionStarted");
    this.element.dispatchEvent(customEvent);
  }

  onResultsMouseUp() {
    const customEvent = new CustomEvent("autocomplete:selectionEnded");
    this.element.dispatchEvent(customEvent);
  }

  open() {
    if (this.element.hidden) {
      this.element.hidden = false;
    }
  }

  deselectCurrentItem() {
    if (this.scrollPosition !== -1) {
      this.items[this.scrollPosition].selected = false;
    }
  }

  itemFor(clickTarget) {
    const domElement = clickTarget.closest(".autocomplete__item");
    return this.items.find((item) => item.isElement(domElement));
  }

  scrollDown() {
    this.open();
    this.deselectCurrentItem();

    if (this.scrollPosition === this.items.length - 1) {
      this.resetScrollPosition();
    }

    const selected = this.items[++this.scrollPosition];
    selected.selected = true;
    selected.scroll();
  }

  scrollUp() {
    this.open();
    this.deselectCurrentItem();

    if (this.scrollPosition === -1 || this.scrollPosition === 0) {
      this.scrollPosition = this.items.length;
    }

    const selected = this.items[--this.scrollPosition];
    selected.selected = true;
    selected.scroll();
  }

  resetScrollPosition() {
    this.scrollPosition = -1;
  }

  update(resultHtml) {
    this.resetScrollPosition();
    this.open();

    const fragment = document
      .createRange()
      .createContextualFragment(resultHtml);
    this.element.innerHTML = "";
    this.element.appendChild(fragment);
  }

  get items() {
    return [...this.element.querySelectorAll("li")].map(
      (el) => new ResultItem(el)
    );
  }

  get selectedItem() {
    return this.items.find((i) => (i.selected = true));
  }
}
