// TODO: delineate public and private interfaces

export default class SearchField {
  constructor(input) {
    this.input = input;
    this.input.addEventListener("input", this.onInputChange.bind(this));
    this.input.addEventListener("keydown", this.onKeyDown.bind(this));
    this.input.addEventListener("blur", this.onBlur.bind(this));
  }

  addEventListener(eventName, handler) {
    this.input.addEventListener(eventName, handler);
  }

  dispatchEvent(eventName, detail) {
    const event = new CustomEvent(eventName, { detail: detail });
    this.input.dispatchEvent(event);
  }

  ignoreKey(key) {
    const ignoreList = ["ArrowRight", "ArrowLeft", " ", "Shift", "Tab"];

    return ignoreList.includes(key);
  }

  isEmpty() {
    return this.value.length === 0;
  }

  onBlur() {
    this.dispatchEvent("autocomplete:blur");
  }

  onInputChange() {
    this.dispatchEvent("autocomplete:searchTermChanged", this.value);
  }

  onKeyDown(event) {
    const { key } = event;

    if (this.ignoreKey(key)) {
      return;
    }

    if (key === "Escape") {
      this.dispatchEvent("autocomplete:quit");
      event.stopPropagation();
      event.preventDefault();
    }

    if (key === "ArrowDown") {
      this.dispatchEvent("autocomplete:scrollDown");
    }

    if (key === "ArrowUp") {
      this.dispatchEvent("autocomplete:scrollUp");
    }

    if (key === "Enter") {
      event.stopPropagation();
      event.preventDefault();
      this.dispatchEvent("autocomplete:itemSelected");
    }
  }

  get value() {
    return this.input.value.trim();
  }

  set value(value) {
    this.input.value = value;
  }
}
