import { Controller } from "@hotwired/stimulus";

import { setErrorMessage } from "../lib/error-utils";
import { DELETE } from "../lib/http-utils";

// DEPRECATED: Use form_rows_controller.js instead
// TODO: Fix delete and add before update error
export default class extends Controller {
  static targets = ["formRow", "messages", "rowTemplate", "tableBody"];

  handleAddRow(e) {
    e.preventDefault();

    const rowCount = this.tableBodyTarget.querySelectorAll("tr").length;

    this.addChildRow(this.tableBodyTarget, rowCount);

    this.dispatch("rowAdded", { target: this.tableBodyTarget });
  }

  handleRemoveRow(e) {
    e.preventDefault();

    if (confirm(`Are you sure you want to delete this ${this.objectName}?`)) {
      // currentTarget gets the link, not the icon in the link
      const targetRowId = e.currentTarget.dataset.rowId;
      const rowToDelete = this.formRowTargets.find(
        (row) => targetRowId == row.dataset.rowId
      );
      const deleteUrl = rowToDelete.dataset.deleteUrl;

      if (typeof deleteUrl !== "undefined") {
        // TODO: Trying adding class to the row so this lookup can go away
        const deleteAction =
          rowToDelete.getElementsByClassName("row__delete-action")[0];
        deleteAction.classList.add("working");

        this.messagesTarget.innerHTML = "";

        DELETE(deleteUrl)
          .then((response) => {
            if (!response.ok) {
              this.handleErrorDeletingRow(deleteUrl);
            } else {
              this.handleRowDeleted(rowToDelete);
            }
          })
          .finally(() => deleteAction.classList.remove("working"));
      } else {
        this.removeRow(rowToDelete);
      }
    }
  }

  handleErrorDeletingRow(url) {
    Rollbar.error(`Could not delete ${this.objectName} at ${url}`);
    setErrorMessage(
      this.messagesTarget,
      `The ${this.objectName} cannot be deleted.`
    );
  }

  handleRowDeleted(row) {
    this.removeRow(row);
  }

  addChildRow(tableBody, rowCount) {
    const template = this.rowTemplateTarget.textContent.replace(
      /ROW_INDEX/g,
      rowCount.toString()
    );

    tableBody.insertAdjacentHTML("beforeend", template);
  }

  removeRow(row) {
    // This is a hidden input generated by Rails that sends the nested model's
    // id with the request. If it's not removed it causes an error on a form
    // submit since the model has been deleted.
    const rowId = row.dataset.rowId;
    const attrsKey = `${this.attrsPrefix}s_attributes`;
    const hiddenIdSelector = `input[name^="${this.paramScope}[${attrsKey}]"][type="hidden"][value="${rowId}"]`;

    const hiddenInput = this.tableBodyTarget.querySelector(hiddenIdSelector);
    if (hiddenInput !== null) {
      hiddenInput.remove();
    }

    row.remove();
    const rowCount = this.tableBodyTarget.childElementCount;
    if (rowCount === 0) {
      this.addChildRow(this.tableBodyTarget, rowCount);
    }
  }

  get attrsPrefix() {
    return this.data.get("attrsPrefix") ?? this.objectName;
  }

  get objectName() {
    return this.data.get("objectName") ?? "row";
  }

  get paramScope() {
    return this.data.get("paramScope") ?? "unknown_scope";
  }
}
