import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// This feature currently has the limitation that it can only support
// one field per form. When I add this to SciPis, and SciPolls, I will
// add additional support for that.
export default class extends Controller {
  // TODO: Add progress target
  static targets = ["field", "pasteDetected", "submit", "warning"];
  static values = {
    modelClassName: String,
    modelAttribute: String,
    contextModelSgid: String,
  };

  connect() {
    this.fieldTarget.addEventListener("paste", this.handlePaste.bind(this));
    this.fieldTarget.addEventListener(
      "trix-change",
      this.handleTextChange.bind(this)
    );
  }

  disconnect() {
    this.fieldTarget.removeEventListener("paste", this.handlePaste.bind(this));
    this.fieldTarget.removeEventListener(
      "trix-change",
      this.handleTextChange.bind(this)
    );
  }

  async handlePaste(event) {
    this.pasteDetected = true;

    this.submitTarget.disabled = true;

    const clipboardData = event.clipboardData || window.clipboardData;
    const text = clipboardData.getData("Text");

    const result = await this._scanText({
      contextModelSgid: this.contextModelSgidValue,
      modelClassName: this.modelClassNameValue,
      modelAttribute: this.modelAttributeValue,
      text: text,
    });

    if (result.aiDetected) {
      this.warningTarget.classList.remove("d-none");
    }
    this.submitTarget.disabled = false;
  }

  handleTextChange() {
    if (
      this.pasteDetected &&
      this.fieldTarget.editor.getDocument().toString().trim().length === 0
    ) {
      this.pasteDetected = false;
      this.warningTarget.innerHTML = "";
    }
  }

  get pasteDetected() {
    return this.pasteDetectedTarget.value === "true";
  }

  set pasteDetected(value) {
    this.pasteDetectedTarget.value = value.toString();
  }

  async _scanText({ text, contextModelSgid, modelClassName, modelAttribute }) {
    const response = await post("/moderation/text_scans", {
      body: JSON.stringify({
        context_model_sgid: contextModelSgid,
        model_class_name: modelClassName,
        model_attribute: modelAttribute,
        text: text,
      }),
      contentType: "application/json",
      responseKind: "json",
    });
    return await response.json;
  }
}
