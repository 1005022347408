import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["disabled"];
  static targets = ["paid", "votingDeadline", "titleWarning"];
  static titleWarningVisible;

  connect() {
    let titleWarningDisplay =
      document.getElementById("ping_title_warning").style.display;

    this.adminNewSaveButton = document.getElementById("admin_new_save_button");

    this.titleWarningVisible = titleWarningDisplay != "none";
  }

  titleChanged(event) {
    if (this.titleWarningVisible) {
      const pingTitle = event.target;
      if (pingTitle.value.includes("?")) this.hideTitleWarning();
    }
  }

  titleBlur(event) {
    const pingTitle = event.target;

    if (pingTitle.value.includes("?")) this.hideTitleWarning();
    else {
      this.titleWarningVisible = true;
      this.showTitleWarning();
    }
  }

  hideTitleWarning() {
    this.titleWarningTargets.forEach((t) => {
      t.style.display = "none";
    });
  }

  showTitleWarning() {
    this.titleWarningTargets.forEach((t) => {
      t.style.display = "block";
    });
  }

  typeChanged(event) {
    const { selectedOptions } = event.target;
    const disableVotingDeadlines =
      !selectedOptions[0].dataset.disallowsVotingDeadlines ||
      selectedOptions[0].dataset.disallowsVotingDeadlines === "true";

    const disablePaidFields =
      !selectedOptions[0].dataset.paid ||
      selectedOptions[0].dataset.paid !== "true";

    const allowDraft = selectedOptions[0].dataset.allowDraft === "true";

    this.togglePaidFields(disablePaidFields);
    this.toggleVotingDeadLineFields(disableVotingDeadlines);
    this.toggleSaveButtonText(allowDraft);
  }

  toggleSaveButtonText(allowDraft) {
    if (allowDraft) {
      this.adminNewSaveButton.innerHTML = "Save as Draft"; // Probably a paid ping
    } else {
      this.adminNewSaveButton.innerHTML = "Save & Publish"; // Pubic pings are published on save
    }
  }

  togglePaidFields(disabled) {
    this.paidTargets.forEach((t) => {
      t.disabled = disabled;
      this.disabledClasses.forEach((c) => {
        t.classList.toggle(c, disabled);
      });
    });
  }

  toggleVotingDeadLineFields(disabled) {
    this.votingDeadlineTargets.forEach((t) => {
      t.disabled = disabled;
      this.disabledClasses.forEach((c) => {
        t.classList.toggle(c, disabled);
      });
    });
  }
}
