export default class ResultItem {
  constructor(element) {
    this.element = element;
  }

  isElement(other) {
    return (this.element = other);
  }

  scroll() {
    this.element.scrollIntoView({ block: "nearest" });
  }

  get label() {
    return this.element.dataset.autocompleteItemLabel || this.element.innerText;
  }

  get selected() {
    return this.element.getAttribute("aria-selected") === "true";
  }

  set selected(value) {
    this.element.setAttribute("aria-selected", value ? "true" : "false");
  }

  get value() {
    return this.element.dataset.autocompleteItemId;
  }
}
