import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "accessTokenCountHint",
    "accessTokenCountInput",
    "accessTokenCountLabel",
    "commentsCloseOnInput",
    "commentsCloseOnLabel",
    "commentsCloseOnHint",
  ];

  accessTypeChanged(event) {
    const { accessType } = event.params;

    if (accessType === "access_token_required") {
      this.accessTokenCountLabelTarget.classList.remove("text-muted");
      this.accessTokenCountHintTarget.classList.remove("text-muted");
      this.accessTokenCountInputTarget.disabled = false;
    } else {
      this.accessTokenCountLabelTarget.classList.add("text-muted");
      this.accessTokenCountHintTarget.classList.add("text-muted");
      this.accessTokenCountInputTarget.disabled = true;
    }
  }

  changeCommentsAllowedState(event) {
    const commentsAllowed = event.target.checked;

    this.commentsCloseOnInputTarget.disabled = !commentsAllowed;
    this.commentsCloseOnHintTarget.classList.toggle("text-muted");
    this.commentsCloseOnLabelTarget.classList.toggle("text-muted");
  }
}
