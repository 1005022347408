/**
 * Contains the state transformation functions for the multi-tab-detection
 * Stimulus controller
 */

/**
 * Returns the initial state for the multi-tab detection controller.
 *
 * @returns {{showWarning: boolean, tabCount: number}}
 */
export function initialState() {
  return { tabCount: 1, showWarning: false };
}

/**
 * Returns a new state object for the multi-tab detection controller, after a
 * tab has been closed.
 *
 * The tabCount will be reduced by one. If the showWarning state is true, and
 * the tabCount is 0, it will be set to false. Otherwise, it will be left as-is.
 * The reason being, if we've shown the warning, and the count is any positive
 * number, then we should continue to show the warning. This is because once the
 * count has gone above 1, we need to assume any open tab may be out of date.
 *
 * @param {{showWarning: boolean, tabCount: number}} previousState The current state of the multi-tab detection controller.
 * @returns {{showWarning: boolean, tabCount: number}}
 */
export function decrementTabCount(previousState) {
  const newTabCount = previousState.tabCount - 1;

  return {
    tabCount: Math.max(newTabCount, 0),
    showWarning: previousState.showWarning ? newTabCount > 0 : false,
  };
}

/**
 * Returns a new state object with the tabCount incremented by 1.
 *
 * @param {{showWarning: boolean, tabCount: number}} previousState The current state of the multi-tab detection controller.
 * @returns {{showWarning: boolean, tabCount: number}}
 */
export function incrementTabCount(previousState) {
  const newTabCount = previousState.tabCount + 1;

  return { tabCount: newTabCount, showWarning: newTabCount > 1 };
}
