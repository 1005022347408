import { Controller } from "@hotwired/stimulus";

// A nice future enhancement would be to scope the event listener to the
// checkbox cell the checkbox is in, but cases like the selection table
// where there are other clickable elements in the row.
export default class extends Controller {
  static targets = ["checkbox"];

  connect() {
    this.selectionMade = false;
    this.element.addEventListener("click", this.#toggleCheckbox.bind(this));
    document.addEventListener(
      "selectionchange",
      this.#trackSelection.bind(this)
    );
  }

  disconnect() {
    this.element.removeEventListener("click", this.#toggleCheckbox.bind(this));
  }

  // If a selection is ever made, we don't want to toggle the checkbox
  #trackSelection() {
    const selection = document.getSelection();
    if (selection.containsNode(this.element, true)) {
      this.selectionMade = this.selectionMade || !selection.isCollapsed;
    }
  }

  #toggleCheckbox(event) {
    if (event.target === this.checkboxTarget) return;
    if (event.target.tagName === "A") return;

    if (!this.selectionMade) {
      this.checkboxTarget.checked = !this.checkboxTarget.checked;
    }

    this.selectionMade = false;
  }
}
