import { Controller } from "@hotwired/stimulus";

// IMPORTANT: This should only be used on the admin UI since it is not robust
// enough. This needs to use the promise API since it it async.
//
// See https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API
export default class extends Controller {
  static targets = ["confirmation"];

  copy(e) {
    e.preventDefault();
    e.stopPropagation();

    let contentToCopy = "";

    if (e.params && e.params.text) {
      contentToCopy = e.params.text;
    } else if (e.params && e.params.target) {
      contentToCopy = document.getElementById(e.params.target).value;
    }

    if (contentToCopy) {
      navigator.clipboard.writeText(contentToCopy);
    }

    if (this.hasConfirmationTarget) {
      this.confirmationTarget.classList.remove("d-none");
      setTimeout(() => this.confirmationTarget.classList.add("d-none"), 2000);
    }
  }
}
