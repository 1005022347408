import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["expertName", "form"];

  handleAddNoteClicked(event) {
    const button = event.target;
    const expertName = button.dataset.expertName;
    const url = button.dataset.createNoteUrl;

    this.formTarget.action = url;
    this.formTarget.querySelector("#notes-expert-name").innerText = expertName;
  }
}
