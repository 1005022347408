import { Controller } from "@hotwired/stimulus";
import { Tab } from "bootstrap";

export default class extends Controller {
  static targets = ["nextButton", "prevButton", "navTabs"];

  connect() {
    this.navTabsTarget.addEventListener("shown.bs.tab", () => {
      this.updateButtonStates();
    });

    const firstTabLink = this.navTabsTarget.querySelector("li:first-child a");

    const firstTab = new Tab(firstTabLink);
    firstTab.show();
  }

  next() {
    const nextTabEl = this.nextTabElement();
    if (nextTabEl) {
      const tab = new Tab(nextTabEl);
      tab.show();
    }
  }

  previous() {
    const prevTabEl = this.prevTabElement();
    if (prevTabEl) {
      const tab = new Tab(prevTabEl);
      tab.show();
    }
  }

  updateButtonStates() {
    const nextTabEl = this.nextTabElement();
    const prevTabEl = this.prevTabElement();

    if (this.hasNextButtonTarget) {
      this.nextButtonTarget.disabled = !nextTabEl;
    }
    if (this.hasPrevButtonTarget) {
      this.prevButtonTarget.disabled = !prevTabEl;
    }
  }

  currentTabElement() {
    return this.element.querySelector(".nav-link.active");
  }

  nextTabElement() {
    return this.currentTabElement()?.parentElement.nextElementSibling?.querySelector(
      '[data-bs-toggle="tab"]'
    );
  }

  prevTabElement() {
    return this.currentTabElement()?.parentElement.previousElementSibling?.querySelector(
      '[data-bs-toggle="tab"]'
    );
  }
}
