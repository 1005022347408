import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    this.bsModal = new Modal(this.modalTarget);

    this.modalTarget.addEventListener("hidden.bs.modal", () =>
      this._resetContent()
    );
  }

  show(event) {
    event.preventDefault();
    const actionTrigger = event.currentTarget;

    const messagesUrl = actionTrigger.href;
    const modalTitle = actionTrigger.dataset.modalTitle;

    fetch(messagesUrl)
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then((response) => response.text())
      .then((text) => this._renderModalBody(text))
      .catch((e) => {
        console.log(e.message);
        this._renderModalBody("An error occurred");
      });

    this.modalTarget.querySelector(".modal-title").innerText = modalTitle;
    this.bsModal.show();
  }

  _renderModalBody(content) {
    this.modalTarget
      .querySelector(".modal-body-loading")
      .classList.add("d-none");
    this.modalTarget.querySelector(".modal-body-content").innerHTML = content;
  }

  _resetContent() {
    console.log("resetting content");
    this.modalTarget
      .querySelector(".modal-body-loading")
      .classList.remove("d-none");
    this.modalTarget.querySelector(".modal-title").innerText = "Messages";
    this.modalTarget.querySelector(".modal-body-content").innerHTML = "";
  }
}
