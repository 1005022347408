import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import ApexCharts from "apexcharts";
import {
  areaChartOptions,
  barChartOptions,
  columnChartOptions,
  donutChartOptions,
} from "../lib/charts/apexChartOptions";

export default class extends Controller {
  static values = { dataUrl: String };

  async connect() {
    const response = await get(this.dataUrlValue);

    if (response.ok) {
      const data = await response.json;
      this.#drawChart(data);
    }
  }

  #drawChart(data) {
    const { chartType } = data;
    let options;
    if (chartType === "area") {
      options = areaChartOptions(data);
    } else if (chartType === "bar") {
      options = columnChartOptions(data);
    } else if (chartType === "doughnut") {
      options = donutChartOptions(data);
    } else if (chartType === "horizontal_bar_chart") {
      options = barChartOptions(data);
    }

    const chart = new ApexCharts(this.element, options);
    chart.render();
  }
}
