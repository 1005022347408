import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["messages"];
  static values = {
    contentTypes: String,
    humanMaxSize: String,
    humanTypes: String,
    maxSize: Number,
  };

  fileAccepted(event) {
    const { file } = event;

    this.messagesTarget.classList.add("d-none");

    if (!this.contentTypes.includes(file.type)) {
      this._setMessage(
        event,
        `<i class="fas fa-exclamation-triangle"></i> Only ${this.humanTypesValue} files are accepted as attachments`
      );
      return;
    }

    if (file.size > this.maxSizeValue) {
      this._setMessage(
        event,
        `<i class="fas fa-exclamation-triangle"></i> Files can be at most ${this.humanMaxSizeValue}`
      );

      return;
    }
  }

  _setMessage(event, message) {
    event.preventDefault();

    this.messagesTarget.innerHTML = message;
    this.messagesTarget.classList.remove("d-none");
  }

  get contentTypes() {
    return this.contentTypesValue.split(",").map((s) => s.trim());
  }
}
