import { Controller } from "@hotwired/stimulus";

import $ from "jquery";

export default class extends Controller {
  static targets = ["content", "toggleLabel", "toggleIcon"];

  connect() {
    $(this.contentTarget).on(
      "shown.bs.collapse hidden.bs.collapse",
      this.handleToggle.bind(this)
    );
  }

  handleToggle() {
    console.log("handleToggle");
    if (this.#isOpen) {
      this.toggleLabelTarget.innerText = "Close";
      this.toggleIconTarget.classList.add("fa-angles-up");
      this.toggleIconTarget.classList.remove("fa-angles-down");
    } else {
      this.toggleLabelTarget.innerText = "Admin Tools";
      this.toggleIconTarget.classList.add("fa-angles-down");
      this.toggleIconTarget.classList.remove("fa-angles-up");
    }
  }

  get #isOpen() {
    return this.contentTarget.classList.contains("show");
  }
}
