import { Controller } from "@hotwired/stimulus";

// Catch-all controller for selection-specific behaviors
export default class extends Controller {
  static targets = ["statusAction"];

  selectionChanged(event) {
    const { selectionCount } = event.detail;

    this.statusActionTargets.forEach((statusAction) => {
      const { confirmationTemplate } = statusAction.dataset;
      const confirmation = confirmationTemplate.replace(
        "{{count}}",
        selectionCount
      );
      statusAction.dataset.turboConfirm = confirmation;
    });
  }
}
