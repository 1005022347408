import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["modal"];

  open(event) {
    const { applicantName, formAction } = event.params;

    this.modalTarget.querySelector(".modal-title").innerText = `Reject ${
      applicantName || "this applicant"
    }?`;
    this.modalTarget.querySelector("form").action = formAction;

    const modal = Modal.getOrCreateInstance(this.modalTarget);
    modal.show();
  }
}
