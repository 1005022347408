import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["expert", "resultAction", "selectedCount", "toField"];

  deselectAll(event) {
    event.preventDefault();

    new Array(
      ...this.element.querySelectorAll(".expert-search-result")
    ).forEach((row) => row.classList.remove("bg-primary"));
    this.setAllCheckboxes(false);
    this.updateTargets();
  }

  handleExpertSelected(event) {
    const checkbox = event.target;
    const row = checkbox.closest(".expert-search-result");
    if (checkbox.checked) {
      row.classList.add("bg-primary");
    } else {
      row.classList.remove("bg-primary");
    }
    this.updateTargets();
  }

  selectAll(event) {
    event.preventDefault();

    new Array(
      ...this.element.querySelectorAll(".expert-search-result")
    ).forEach((row) => row.classList.add("bg-primary"));
    this.setAllCheckboxes(true);
    this.updateTargets();
  }

  setAllCheckboxes(checked) {
    this.expertTargets.forEach((expert) => {
      const checkbox = expert.querySelector(".select-expert");
      if (!checkbox.disabled) {
        checkbox.checked = checked;
      }
    });
    this.updateTargets();
  }

  updateTargets() {
    const selectedCount = this.selectedExperts.length;
    this.resultActionTargets.forEach(
      (action) => (action.disabled = selectedCount === 0)
    );
    this.selectedCountTargets.forEach(
      (target) => (target.innerText = selectedCount)
    );
  }

  get selectedExperts() {
    return this.expertTargets.filter(
      (expert) => expert.querySelector(".select-expert")?.checked
    );
  }
}
