import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tokenField"];
  static values = { action: String, key: String };

  verify(event) {
    event.preventDefault();

    const tokenField = this.tokenFieldTarget;
    const form = this.element;

    grecaptcha.ready(() => {
      grecaptcha
        .execute(this.keyValue, { action: this.actionValue })
        .then((token) => {
          tokenField.value = token;
          form.submit();
        });
    });
  }
}
