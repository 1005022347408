export function setErrorMessage(
  errorTarget,
  message = "This action could not be performed"
) {
  errorTarget.innerHTML = `
<div class="alert alert-danger" role="alert">
  <p><strong>An error happened: </strong>${message}</p>
  <p>We've been alerted to this error, and should have it fixed soon.</p>
  <p>You can try again now. But if the error persists, please try again later.</p>
</div>
`;
}
