import { Controller } from "@hotwired/stimulus";

import Autocomplete, { ItemList, SearchField } from "../lib/autocomplete";

export default class extends Controller {
  static targets = ["hiddenField", "searchField", "resultList"];
  static values = { searchUrl: String };

  connect() {
    const searchField = new SearchField(this.searchFieldTarget);
    const resultList = new ItemList(this.resultListTarget);

    new Autocomplete(
      this.element,
      this.searchUrlValue,
      searchField,
      resultList,
      this.hiddenFieldTarget
    );
  }
}
