export default class Autocomplete {
  constructor(element, searchUrl, searchField, resultList, hiddenField) {
    this.element = element;
    this.searchUrl = searchUrl;
    this.searchField = searchField;
    this.searchField.addEventListener(
      "autocomplete:searchTermChanged",
      this.onSearchTermChanged.bind(this)
    );
    this.searchField.addEventListener(
      "autocomplete:scrollDown",
      this.onScrollDown.bind(this)
    );
    this.searchField.addEventListener(
      "autocomplete:scrollUp",
      this.onScrollUp.bind(this)
    );
    this.searchField.addEventListener(
      "autocomplete:itemSelected",
      this.onItemSelected.bind(this)
    );
    this.searchField.addEventListener(
      "autocomplete:blur",
      this.onBlur.bind(this)
    );
    this.searchField.addEventListener(
      "autocomplete:quit",
      this.onQuit.bind(this)
    );

    this.resultsList = resultList;
    this.resultsList.addEventListener(
      "autocomplete:itemClicked",
      this.onClick.bind(this)
    );
    this.resultsList.addEventListener(
      "autocomplete:selectionStarted",
      this.onSelectionStarted.bind(this)
    );

    this.hiddenField = hiddenField;

    this.selectionInProgress = false;
  }

  addEventListener(eventName, handler) {
    this.element.addEventListener(eventName, handler);
  }

  clearSelection() {
    this.hiddenField.value = "";
  }

  commit(item) {
    const { label, value } = item;
    this.searchField.value = label;
    this.hiddenField.value = value;
    this.resultsList.close();
  }

  dispatchEvent(eventName, detail) {
    const event = new CustomEvent(eventName, { detail: detail });
    this.element.dispatchEvent(event);
  }

  onBlur() {
    if (this.selectionInProgress) return;
    this.resultsList.close();
  }

  onClick(event) {
    const { selectedItem } = event.detail;
    this.commit(selectedItem);
  }

  onItemSelected() {
    const selectedItem = this.resultsList.selectedItem;
    this.commit(selectedItem);
  }

  onScrollDown() {
    if (this.searchField.isEmpty()) return;

    this.resultsList.scrollDown();
  }

  onScrollUp() {
    if (this.searchField.isEmpty()) return;

    this.resultsList.scrollUp();
  }

  onSearchTermChanged(event) {
    const searchTerm = event.detail;

    this.clearSelection();

    if (searchTerm.length > 1) {
      this.search(searchTerm);
    } else {
      this.resultsList.clear();
    }
  }

  onSelectionStarted() {
    this.selectionInProgress = true;
    this.resultsList.addEventListener(
      "autocomplete:selectionEnded",
      () => (this.selectionInProgress = false),
      {
        once: true,
      }
    );
  }

  onQuit() {
    this.resultsList.clear();
  }

  search(query) {
    const url = `${this.searchUrl}?query=${query}`;
    fetch(url, {
      credentials: "same-origin",
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText);

        return response.text();
      })
      .then(this.handleResults.bind(this))
      .catch(() => this.dispatchEvent("autocomplete:searchError"));
  }

  handleResults(html) {
    if (html.length === 0) {
      this.resultsList.clear();
      return;
    }
    this.resultsList.update(html);
  }

  get options() {
    return [...this.htmlSelect.querySelectorAll("option")].map((o) => o.text);
  }
}
