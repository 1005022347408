import { Controller } from "@hotwired/stimulus";
import { Tooltip } from "bootstrap";
import { mapBS5AttributesToBS4 } from "../lib/bootstrap_compat_util"; // Remove after Bootstrap 5 upgrade

export default class extends Controller {
  connect() {
    mapBS5AttributesToBS4(this.element); // Remove after Bootstrap 5 upgrade
    new Tooltip(this.element);
  }
}
