import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // Delay making full menu text visible on result sidebar
    var timer;
    var delay = 1000;

    var sidebarNavLinks = document.querySelectorAll(
      "#sidebar_nav li.result > a"
    );

    sidebarNavLinks.forEach(function (link) {
      link.addEventListener("mouseenter", function () {
        var self = this;
        timer = setTimeout(function () {
          self.classList.add("result-title-visible");
        }, delay);
      });

      link.addEventListener("mouseleave", function () {
        clearTimeout(timer);
        this.classList.remove("result-title-visible");
      });
    });
  }
}
