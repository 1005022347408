import { Controller } from "@hotwired/stimulus";
import defaultWeight from "../lib/defaultWeight";

/**
 * The controller responds to events on score weight fields, and rebalances
 * the weights based on the number of editable fields.
 */
export default class extends Controller {
  static targets = ["field", "trigger"];

  fieldTargetConnected(target) {
    target.addEventListener("blur", () => this.handleWeightChange(target));
  }

  handleWeightChange(target) {
    if (Number(target.value) !== 0) return;

    target.readOnly = true;

    this.rebalanceWeights();
  }

  rebalanceWeights() {
    const defaultWeightValue = defaultWeight(this.editableFields.length);

    this.editableFields.forEach((field) => (field.value = defaultWeightValue));
    this.readOnlyFields.forEach((field) => (field.value = "0.0"));
  }

  get readOnlyFields() {
    return this.fieldTargets.filter((field) => field.readOnly);
  }

  get editableFields() {
    return this.fieldTargets.filter((field) => !field.readOnly);
  }
}
