import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["row"];
  static targets = ["container", "template"];

  add(e) {
    e.preventDefault();

    const rowCount = this.containerTarget.querySelectorAll(
      `.${this.rowClass}`
    ).length;

    const template = this.templateTarget.textContent.replace(
      /ROW_INDEX/g,
      rowCount.toString()
    );

    this.containerTarget.insertAdjacentHTML("beforeend", template);
  }
}
