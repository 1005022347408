import { Controller } from "@hotwired/stimulus";

// Consider this a starter implementation of a dialog controller
// There may be times way have multiple potential dialogs on a
// page, and other considerations. Do not hesitate to modify this
// to fit those needs.
export default class extends Controller {
  static targets = ["dialog"];
  static values = { open: Boolean };

  connect() {
    if (this.openValue) {
      this.dialogTarget.showModal();
    }
  }

  open(event) {
    event.preventDefault();

    this.dialogTarget.showModal();
  }

  close() {
    this.dialogTarget.close();
  }
}
