/**
 * Forward compatibility with Bootstrap 5, allow us to use Bootstrap 5 data attributes in views while running Bootstrap 4
 * To be removed after upgrading to Bootstrap 5
 */

// Maps data-bs-* attributes to data-* attributes
export function mapBS5AttributesToBS4(element) {
  Object.keys(element.dataset).forEach((key) => {
    if (key.startsWith("bs")) {
      const bs4Key = key.charAt(2).toLowerCase() + key.slice(3);
      element.dataset[bs4Key] = element.dataset[key];
    }
  });
}
