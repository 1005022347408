import "bootstrap";
import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import List from "list.js";
import "./controllers";

ActiveStorage.start();
Rails.start();

import "trix";
import "@rails/actiontext";

document.addEventListener("DOMContentLoaded", function () {
  // Init lists
  const lists = Array.from(document.querySelectorAll("[data-list]"));
  lists.forEach(initList);
});

function initList(list) {
  // Guard clause for empty list
  if (Object.is(list.querySelector(".list"), null)) return;

  const listOptions = list.dataset.list && JSON.parse(list.dataset.list);
  const defaultOptions = {
    listClass: "list",
    searchClass: "list-search",
    sortClass: "list-sort",
  };

  // Merge options
  const options = Object.assign(defaultOptions, listOptions);

  const listPaginationPrev = list.querySelector(".list-pagination-prev");
  const listPaginationNext = list.querySelector(".list-pagination-next");
  const listTags = Array.from(list.querySelectorAll("[data-search-term]"));
  const listResetButton = list.querySelector(".reset-search");

  // Init
  const listObj = new List(list, options);

  if (listPaginationNext) {
    listPaginationNext.addEventListener("click", function (e) {
      e.preventDefault();

      const nextItem = listObj.i + listObj.page;

      if (nextItem <= listObj.size()) {
        listObj.show(nextItem, listObj.page);
      }
    });
  }

  if (listPaginationPrev) {
    listPaginationPrev.addEventListener("click", function (e) {
      e.preventDefault();

      const prevItem = listObj.i - listObj.page;

      if (prevItem > 0) {
        listObj.show(prevItem, listObj.page);
      }
    });
  }

  if (listResetButton) {
    listResetButton.addEventListener("click", function (e) {
      e.preventDefault();
      triggerSearch(listObj, "");
    });
  }

  // console.log(listObj);

  // Temporary to allow dynamic taggin within lists
  initTags(listObj, listTags);

  // Handle list update
  listObj.on("updated", function () {
    // Add other things that need to be re-initialized here
  });
}

function triggerSearch(listObj, searchTerm) {
  const searchField = listObj.listContainer.querySelector(
    "." + listObj.searchClass
  );
  searchField.value = searchTerm;
  listObj.search(searchTerm);
}

function initTags(listObj, listTags) {
  // Activate tags for quick on-page filtering
  listTags.forEach(function (listTag) {
    console.log("initTag!s");
    listTag.addEventListener("click", function (e) {
      e.preventDefault();
      triggerSearch(listObj, this.dataset.searchTerm);
    });
    listTag.setAttribute(
      "title",
      "Filter current results by: " + listTag.dataset.searchTerm
    );
    listTag.classList.add("clickable-tag");
  });
}
