import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element"];

  show(e) {
    e.preventDefault();

    this.elementTarget.classList.remove("d-none");
  }

  hide(e) {
    e.preventDefault();

    this.elementTarget.classList.add("d-none");
  }
}
