import csrfToken from "./csrf_token";

export function DELETE(url) {
  return fetch(url, {
    credentials: "same-origin",
    headers: {
      "X-CSRF-Token": csrfToken(),
    },
    method: "DELETE",
  });
}

export function POST(url, data) {
  return fetch(url, {
    body: JSON.stringify(data),
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken(),
    },
    method: "POST",
  }).then(checkStatus);
}

export const checkStatus = (response) => {
  if (!response.ok) {
    throw new Error(response.statusText);
  }

  // for HEAD responses
  if (!response.bodyUsed) return;

  return response.json();
};
