import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element"];

  close(event) {
    event.preventDefault();

    event.target.classList.remove("bg-bark");
    this.elementTarget.classList.add("d-none");
  }

  toggle(event) {
    event.preventDefault();

    event.target.classList.toggle("bg-bark");
    this.elementTarget.classList.toggle("d-none");
  }
}
