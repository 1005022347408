import { Controller } from "@hotwired/stimulus";

/**
 * Allows 'value' targets to listen for events that are dispatched
 * within the scope (i.e. childeen in the DOM) of this controller,
 * and to update their value.
 *
 * The target element should have a 'data-value-updater-events' attribute
 * with a JSON object in the form of
 *  {"<controller-identifier?:<event_name>":<attribute>}
 *
 * For example:
 * <span data-value-updater-target="value"
 *       data-value-updater-events='{"vote:cast":"voteCount"}>
 * </span>
 * This will listen for 'cast' events from the 'vote' controller and
 * update its contents with the 'voteCount' attribute from the event detail.
 */
export default class extends Controller {
  static targets = ["value"];

  connect() {
    this.attachListenersToTargets([...this.valueTargets]);
  }

  attachListenersToTargets(targets) {
    targets.forEach((target) => {
      const events = JSON.parse(target.dataset.targetEvents);

      for (const eventName in events) {
        this.element.addEventListener(
          eventName,
          this.onValueChanged.bind(this, target, events[eventName])
        );
      }
    });
  }

  onValueChanged(target, property, { detail: { [property]: value } }) {
    if (value !== undefined || value !== null) target.innerHTML = value;
  }
}
