import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("focus", this.selectText.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("focus", this.selectText.bind(this));
  }

  selectText() {
    this.element.select();
  }
}
