import { Controller } from "@hotwired/stimulus";
// Would have named this application controller, but that gets confusing

export default class extends Controller {
  connect() {
    // Warn if applying without an application statement
    let applicant_statment = document.getElementById(
      "invite_applicant_statement"
    );

    this.element.addEventListener("submit", (e) => {
      if (applicant_statment.textContent.length > 0) return true;

      return window.confirm(
        "You have not completed your applicant statement. Do you want to proceed anyway?"
      )
        ? true
        : e.preventDefault();
    });
  }
}
