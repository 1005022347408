export function formatDonutLegendLabel(data, seriesName, opts) {
  return [
    seriesName,
    ": ",
    opts.w.globals.series[opts.seriesIndex],
    `<span class="text-secondary">(${data.proportions[opts.seriesIndex]}%)</span>`,
  ];
}

export function formatBarChartLabel(label, breakpoint = 25) {
  if (label.length <= breakpoint) return label;

  const words = label.split(" ");
  const lines = [];
  let currentSubstring = "";

  words.forEach((word) => {
    // Check if adding the word and a space would exceed the breakpoint
    if (
      currentSubstring.length +
        word.length +
        (currentSubstring.length ? 1 : 0) <=
      breakpoint
    ) {
      // If not, add the word to the current substring
      currentSubstring += (currentSubstring.length ? " " : "") + word;
    } else {
      // If it would exceed, push the current substring to the result array
      lines.push(currentSubstring);
      // Start a new substring with the current word
      currentSubstring = word;
    }
  });

  // Push the last substring to the result array
  if (currentSubstring.length) {
    lines.push(currentSubstring);
  }

  return lines;
}
