import { Controller } from "@hotwired/stimulus";
import { POST } from "../../lib/http-utils";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = [
    "confirmationMessage",
    "defaultButtonText",
    "errorAlert",
    "message",
    "recipient",
    "sendingText",
    "subject",
    "submitButton",
    "toField",
  ];

  connect() {
    const modal = document.getElementById("messageModal");
    modal.addEventListener("shown.bs.modal", () => {
      this.toFieldTarget.innerHTML = "";
      this.recipients.forEach((r) => {
        const recipientDiv = document.createElement("div");

        if (r.unconfirmed == "true") {
          const unconfirmedBadge = document.createElement("span");

          unconfirmedBadge.className = "badge bg-warning";
          unconfirmedBadge.innerText = "Unconfirmed";
          recipientDiv.appendChild(unconfirmedBadge);
          recipientDiv.appendChild(document.createTextNode(" "));
        }
        const email = document.createTextNode(r.email);
        recipientDiv.appendChild(email);
        recipientDiv.appendChild(document.createTextNode(","));

        this.toFieldTarget.appendChild(recipientDiv);
      });

      this.setInitialState();
      this.toggleSubmitButtonState();
    });
  }

  handleInputChange() {
    this.toggleSubmitButtonState();
  }

  async handleSendMessage(event) {
    event.preventDefault();

    try {
      if (confirm("Send this message?")) {
        this.setSendingState();

        await POST("/admin/messages", {
          content: this.message,
          recipient_ids: this.recipients.map((r) => r.id),
          subject: this.subject,
        });

        this.resetFields();
        this.hideModal();
      }
    } catch {
      this.setErrorState();
    } finally {
      this.unsetSendingState();
    }
  }

  hideModal() {
    const modal = document.getElementById("messageModal");
    const bsModal = Modal.getInstance(modal);
    bsModal.hide();
  }

  hideErrorAlert() {
    this.errorAlertTarget.classList.add("d-none");
  }

  hideDefaultButton() {
    this.defaultButtonTextTarget.classList.add("d-none");
    this.defaultButtonTextTarget.classList.remove("d-inline-block");
  }

  hideSendingButton() {
    this.sendingTextTarget.classList.add("d-none");
    this.sendingTextTarget.classList.remove("d-inline-block");
  }

  resetFields() {
    this.messageTarget.value = "";
    this.subjectTarget.value = "";
  }

  setInitialState() {
    this.hideErrorAlert();
    this.hideSendingButton();
    this.showDefaultButton();
  }

  setErrorState() {
    this.errorAlertTarget.classList.remove("d-none");
  }

  setSendingState() {
    this.hideErrorAlert();
    this.showSendingButton();
    this.hideDefaultButton();
  }

  showDefaultButton() {
    this.defaultButtonTextTarget.classList.remove("d-none");
    this.defaultButtonTextTarget.classList.add("d-inline-block");
  }

  showSendingButton() {
    this.sendingTextTarget.classList.remove("d-none");
    this.sendingTextTarget.classList.add("d-inline-block");
  }

  toggleSubmitButtonState() {
    this.submitButtonTarget.disabled =
      this.message.length === 0 || this.subject.length === 0;
  }

  unsetSendingState() {
    this.hideSendingButton();
    this.showDefaultButton();
  }

  get message() {
    return this.messageTarget.value;
  }

  get recipients() {
    return this.recipientTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => {
        return {
          id: checkbox.dataset.recipientId,
          email: checkbox.dataset.recipientEmail,
          unconfirmed: checkbox.dataset.unconfirmed,
        };
      });
  }

  get subject() {
    return this.subjectTarget.value;
  }
}
