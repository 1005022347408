import { Controller } from "@hotwired/stimulus";
import {
  decrementTabCount,
  incrementTabCount,
  initialState,
} from "./multiTabDetection/helpers";

/**
 * Tracks how many tabs are opened for a given named context.
 *
 * This is useful for showing a warning when a user has multiple tabs open.
 *
 * IMPORTANT: Make sure the context value is unique. e.g. "submissions-123", for
 *            the SciPi submissions form, and not just "submissions" since that
 *            would be shared across all submissions pages fpr all SciPis
 */
export default class extends Controller {
  static targets = ["button", "warning"];
  static values = { context: String, disabled: Boolean, userId: Number };

  connect() {
    if (this.disabledValue) return;

    this.initializeTabState();
    window.addEventListener("storage", this.handleStorageEvent.bind(this));
    window.addEventListener("unload", this.handleBeforeUnload.bind(this));

    if (this.state.showWarning) {
      this.showWarning();
    }
  }

  buttonTargetConnected(button) {
    if (this.disabledValue) return;

    button.addEventListener("click", this.handleButtonClick.bind(this));
  }

  buttonTargetDisconnected(button) {
    if (this.disabledValue) return;

    button.removeEventListener("click", this.handleButtonClick.bind(this));
  }

  handleBeforeUnload() {
    this.state = decrementTabCount(this.state);
  }

  handleButtonClick(event) {
    if (!this.state.showWarning) return;

    if (
      !confirm(
        "You may have edited this review in multiple tabs. Please double check your work to make sure it's correct. Click 'Cancel' to go back, or 'OK' to save."
      )
    ) {
      event.preventDefault();
    }
  }

  handleStorageEvent(event) {
    if (event.key !== this.keyName) return;

    if (this.state.showWarning) {
      this.showWarning();
    }
  }

  initializeTabState() {
    if (typeof window.localStorage[this.keyName] === "undefined") {
      this.state = initialState();
    } else {
      this.state = incrementTabCount(this.state);
    }
  }

  showWarning() {
    Rollbar.info("Showing multi-tab warning", {
      userId: this.userIdValue,
      context: this.contextValue,
    });

    this.warningTarget.classList.remove("d-none");
  }

  get keyName() {
    return `${this.identifier}:${this.contextValue}`;
  }

  get state() {
    return JSON.parse(window.localStorage[this.keyName]);
  }

  set state(value) {
    window.localStorage[this.keyName] = JSON.stringify(value);
  }
}
