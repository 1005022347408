import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {
    // Custom File Upload buttons ----------------
    // https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/

    $(".inputfile").each(function () {
      var $input = $(this),
        $label = $input.next("label"),
        labelVal = $label.html();

      $input.on("change", function (e) {
        var fileName = "";

        if (e.target.value) fileName = e.target.value.split("\\").pop();

        if (fileName) $label.find("span").html(fileName);
        else $label.html(labelVal);
      });
    });

    // Handle unsaved changes and confirm before leaving page ---------------
    var isDirty = false;

    $(
      "body.answer_groups_traditional form input, body.answer_groups_traditional form textarea"
    ).change(function () {
      isDirty = true;
      $(".unsaved-changes").html("You Have Unsaved Changes.");
    });

    $(window).bind("beforeunload", function () {
      if (isDirty) return "Unsaved changes will be lost if you leave the page.";
    });

    $("button[type='submit'], input[type='submit']").click(function () {
      $(window).unbind("beforeunload");
    });
  }
}
